import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { LayoutDefault } from "../components/LayoutDefault";
import { CenteredContentWrapper, PageHeader } from "@xolvio/xolvio-ui";

// eslint-disable-next-line
export default ({ location }) => {
  return (
    <LayoutDefault>
      <Content>
        <PageHeader>Oops, something went wrong...</PageHeader>
        Go to <Link to="/">homepage</Link>
      </Content>
    </LayoutDefault>
  );
};

const Content = styled(CenteredContentWrapper)`
  padding: 50px 0;
  min-height: calc(100vh - 500px);
`;
